import React from 'react';
import Alert from '@material-ui/lab/Alert';
import { useHistory } from 'react-router';

export const AlertComponent = (props) => {
    const history = useHistory();

    const message = props.message;
    const path = props.path;
    // el tipo puede ser: error, warning
    const type = props.type;

    return (
        <Alert
            onClose={() => {
                history.push(path);
            }}
            style={{ marginTop: '5%' }}
            variant="filled"
            severity={type}
        >
            {message}
        </Alert>
    );
};
