import React from 'react';
import { Redirect } from 'react-router-dom';

import dashboardRoutes from './views/dashboard/DashboardRoutes';
import utilitiesRoutes from './views/utilities/UtilitiesRoutes';
import sessionRoutes from './views/sessions/SessionRoutes';

import materialRoutes from './views/material-kit/MaterialRoutes';

import mapRoutes from './views/map/MapRoutes';

// RUTAS DE BAIRU
import sucursalesRoutes from './views/sucursales/sucursalesRoutes';
import productosRoutes from './views/productos/productosRoutes';
import orderTablesRoutes from './views/orderTables/orderTablesRoutes';
import kitchenTablesRoutes from './views/kitchenTables/kitchenTablesRoutes';
import pedidosRoutes from './views/ventas/ventasRoutes';
import almacenRoutes from './views/almacen/almacenRoutes';
import administradoresRoutes from './views/administradores/administradoresRoutes';

const redirectRoute = [
    {
        path: '/',
        exact: true,
        component: () => <Redirect to="/dashboard/analytics" />,
    },
];

const errorRoute = [
    {
        component: () => <Redirect to="/session/404" />,
    },
];

const routes = [
    ...sessionRoutes,
    ...dashboardRoutes,
    ...materialRoutes,
    ...utilitiesRoutes,
    // ...dragAndDropRoute,
    ...mapRoutes,
    ...redirectRoute,
    // BAIRU ROUTES...deben estar antes del errorRoute
    ...sucursalesRoutes,
    ...productosRoutes,
    ...orderTablesRoutes,
    ...kitchenTablesRoutes,
    ...pedidosRoutes,
    ...almacenRoutes,
    ...administradoresRoutes,
    // FIN BAIRU ROUTES
    // errorRoute siempre debe estar al final
    ...errorRoute,
];

export default routes;
