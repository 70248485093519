import React from 'react';

const sucursalesRoutes = [
    {
        path: '/sucursales/crear',
        component: React.lazy(() => import('./Crear/Crear')),
    },
    {
        path: '/sucursales/mesas',
        component: React.lazy(() => import('./Mesas/Mesas')),
    },
    {
        path: '/sucursales/listar',
        component: React.lazy(() => import('./Listar/Listar')),
    },
];

export default sucursalesRoutes;
