import React, { useState } from "react";
import {
  Card,
  // Checkbox,
  // FormControlLabel,
  CircularProgress,
  Grid,
  Button
} from "@material-ui/core";
import { AlertComponent } from '../material-kit/alerts/alert';
import { useHistory } from "react-router";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
// APOLLO GRAPHQL
import { CREATE_RESTAURANT_AND_ADMIN } from '../../graphql/mutations';
import { useMutation } from '@apollo/client';



export const SignUp = () => {
  const history = useHistory();
  const [restaurantName, setRestaurantName] = useState('')
  const [description, setDescription] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  // APOLLO GRAPHQL
  const [
    signUpAdminRestaurant,
    { loading: signUpLoading, error: signUpError }
  ] = useMutation(CREATE_RESTAURANT_AND_ADMIN,{
    onCompleted: data => {
      console.log(data);
      history.push("/session/signin")
    },
    onError: error => {
      console.log(error.message)
      setRestaurantName('');
      setDescription('')
      setEmail('');
      setPassword('');
    }
  });

  // CHANGE HANDLERS
  const nameChange = event => {
    setRestaurantName(event.target.value);
  }
  const descriptionChange = event => {
    setDescription(event.target.value)
  }
  const emailChange = event => {
    setEmail(event.target.value)
  }
  const passwordChange = event => {
    setPassword(event.target.value);
  }


  // FUNCTIONS
  const handleFormSubmit = () => {
    try {
      signUpAdminRestaurant({
        variables: {
          restaurantName: restaurantName,
          restaurantDescription: description,
          adminEmail: email,
          adminPassword: password,
        }
      })

    } catch (e) {
      console.log(e)
    }
  };
  return (
    <div className="signup flex justify-center w-full h-full-screen">
      <div className="p-8">
        <Card className="signup-card position-relative y-center">
          <Grid container>
            <Grid item lg={5} md={5} sm={5} xs={12}>
              <div className="p-8 flex justify-center bg-light-gray items-center h-full">
                <img
                  src="/assets/images/illustrations/posting_photo.svg"
                  alt=""
                />
              </div>
            </Grid>
            <Grid item lg={7} md={7} sm={7} xs={12}>
              <div className="p-9 h-full">
                <ValidatorForm  onSubmit={handleFormSubmit}>
                  <TextValidator
                    className="mb-6 w-full"
                    variant="outlined"
                    label="Nombre de tu restaurante"
                    onChange={nameChange}
                    type="text"
                    name="restaurantName"
                    value={restaurantName}
                    validators={["required"]}
                    errorMessages={["Este campo es requerido"]}
                  />
                  <TextValidator
                    className="mb-6 w-full"
                    variant="outlined"
                    label="descripción de tu restaurante (opcional)"
                    onChange={descriptionChange}
                    type="text"
                    name="description"
                    value={description}
                    // validators={["required"]}
                    // errorMessages={["this field is required"]}
                  />
                  <TextValidator
                    className="mb-6 w-full"
                    variant="outlined"
                    label="Correo electrónico"
                    onChange={emailChange}
                    type="email"
                    name="email"
                    value={email}
                    validators={["required", "isEmail"]}
                    errorMessages={[
                      "Este campo es requerido",
                      "email no válido"
                    ]}
                  />
                  <TextValidator
                    className="mb-4 w-full"
                    label="Contraseña"
                    variant="outlined"
                    onChange={passwordChange}
                    name="password"
                    type="password"
                    value={password}
                    validators={["required"]}
                    errorMessages={["Este campo es requerido"]}
                  />
                  {/* <FormControlLabel
                    className="mb-4"
                    name="agreement"
                    onChange={this.handleChange}
                    control={<Checkbox />}
                    label="I have read and agree to the terms of service."
                  /> */}
                  <div className="flex items-center">
                    <Button
                      disabled={signUpLoading}
                      className="capitalize"
                      variant="contained"
                      color="primary"
                      type="submit"
                    >
                      Registrarse
                    </Button>
                    { signUpLoading && <CircularProgress size={24} /> }
                    <span className="mx-2 ml-5">o</span>
                    <Button
                      disabled={signUpLoading}
                      className="capitalize"
                      onClick={() =>
                        history.push("/session/signin")
                      }
                    >
                      Iniciar sesión
                    </Button>
                  </div>
                </ValidatorForm>
                { signUpError && (
                    <AlertComponent 
                      message={'Usted ya creó un restaurante. Inicie sesión con su cuenta'} 
                      path={'/session/signin'}
                      type={'warning'}
                    />
                  )}
              </div>
            </Grid>
          </Grid>
        </Card>
      </div>
      
    </div>
  );
}

// const mapStateToProps = state => ({
//   // setUser: PropTypes.func.isRequired
// });

// export default connect(mapStateToProps, {})(SignUp);
