let intialState = {
    // ESTADO INICIAL AL CREAR UNA SUCURSAL
    mapGeoLocation: {
        lat: null,
        lng: null,
    },
    // ESTADO INICIAL AL LISTAR LAS SUCURSALES
    locations: [],
};

const SucursalReducers = function (state = intialState, action) {
    switch (action.type) {
        case 'LOCATION_MAP_SUCURSAL':
            const newState = { ...state };

            newState.mapGeoLocation = {
                lat: action.geoLocation.lat,
                lng: action.geoLocation.lng,
            };

            return {
                mapGeoLocation: newState.mapGeoLocation,
            };
        case 'LOCATION_MAP_INITIAL_STATE':
            const defaultState = { ...state };

            defaultState.mapGeoLocation = {
                lat: null,
                lng: null,
            };
            return {
                defaultState,
            };
        case 'ADD_LOCATIONS_IN_MAP':
            const newLocation = { ...state };
            newLocation.locations = action.addLocations;

            console.log(newLocation);

            return {
                ...(state.locations = newLocation),
            };
        case 'UPDATE_LOCATION_IN_MAP':
            let newUpdated = { ...state };

            let newObject = {
                code: action.location.code,
                id: action.location.id,
                latitude: action.location.latitude,
                longitude: action.location.longitude,
            };

            newUpdated.locations[action.indexLocation] = newObject;

            return {
                ...(state.locations = newUpdated),
            };
        default: {
            return { ...state };
        }
    }
};

export default SucursalReducers;
