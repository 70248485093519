import React, { Component } from 'react';
import { Card, Grid, Button, CircularProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { loginWithEmailAndPassword } from '../../redux/actions/LoginActions';

const styles = (theme) => ({
	wrapper: {
		position: 'relative',
	},

	buttonProgress: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12,
	},
});

class SignIn extends Component {
	state = {
		email: '',
		password: '',
	};
	handleChange = (event) => {
		event.persist();
		this.setState({
			[event.target.name]: event.target.value,
		});
	};
	handleFormSubmit = (event) => {
		console.log(this.state);

		this.props.loginWithEmailAndPassword({
			email: this.state.email,
			token: this.state.password,
		});
	};
	render() {
		let { email, password } = this.state;
		let { classes } = this.props;
		return (
			<div className="signup flex justify-center w-full h-full-screen">
				<div className="p-8">
					<Card className="signup-card position-relative y-center">
						<Grid container>
							<Grid item lg={5} md={5} sm={5} xs={12}>
								<div className="p-8 flex justify-center items-center h-full">
									{/* <img src="/assets/images/illustrations/dreamer.svg" alt="" /> */}
									<img src="/assets/images/logo-bairu.svg" alt="" />
								</div>
							</Grid>
							<Grid item lg={7} md={7} sm={7} xs={12}>
								<div className="p-9 h-full bg-light-gray position-relative">
									<h1>Bienvenido a Bairu</h1>
									<ValidatorForm ref="form" onSubmit={this.handleFormSubmit}>
										<TextValidator
											className="mb-6 w-full"
											variant="outlined"
											label="Correo electrónico"
											onChange={this.handleChange}
											type="email"
											name="email"
											autoComplete="off"
											value={email}
											validators={['required', 'isEmail']}
											errorMessages={[
												'Este campo es requerido',
												'El email no es válido',
											]}
										/>
										<TextValidator
											className="mb-3 w-full"
											label="Contraseña"
											variant="outlined"
											onChange={this.handleChange}
											name="password"
											type="password"
											value={password}
											validators={['required']}
											errorMessages={['Este campo es requerido']}
										/>

										<div className="flex flex-wrap items-center mb-4">
											<div className={classes.wrapper}>
												<Button
													variant="contained"
													color="primary"
													disabled={this.props.login.loading}
													type="submit"
												>
													Ingresar
												</Button>
												{this.props.login.loading && (
													<CircularProgress
														size={24}
														className={classes.buttonProgress}
													/>
												)}
											</div>
											<span className="mx-2 ml-5">o</span>
											<Button
												className="capitalize"
												disabled={this.props.login.loading}
												onClick={() =>
													this.props.history.push('/session/signup')
												}
											>
												Registrarse
											</Button>
										</div>
									</ValidatorForm>
								</div>
							</Grid>
						</Grid>
					</Card>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	loginWithEmailAndPassword: PropTypes.func.isRequired,
	login: state.login,
});
export default withStyles(styles, { withTheme: true })(
	withRouter(connect(mapStateToProps, { loginWithEmailAndPassword })(SignIn))
);
