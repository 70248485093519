import { gql } from '@apollo/client';

export const CERRAR_CAJA = gql`
    mutation DASHBOARD_cerrarCaja(
        $cajaId: Int!
        $branchOfficeId: Int! 
    ) {
        DASHBOARD_cerrarCaja(
            cajaId: $cajaId
            branchOfficeId: $branchOfficeId
        ) {
            id
        }
    }
`;

export const LOGIN_ADMIN = gql`
    mutation LoginAdmin($email: String!, $password: String!) {
        loginAdmin(email: $email, password: $password) {
            id
            email
            token
            password
        }
    }
`;

export const CREATE_RESTAURANT_AND_ADMIN = gql`
    mutation CreateRestaurantAndAdmin(
        $restaurantName: String!
        $restaurantDescription: String
        $adminEmail: String!
        $adminPassword: String!
    ) {
        createRestaurantAndAdmin(
            restaurantName: $restaurantName
            restaurantDescription: $restaurantDescription
            adminEmail: $adminEmail
            adminPassword: $adminPassword
        ) {
            id
        }
    }
`;

export const CREATE_ORDER_TABLE = gql`
    mutation CreateOrderTable($name: String!, $branchOffice: Int!) {
        createOrderTable(name: $name, branchOffice: $branchOffice) {
            id
        }
    }
`;

export const CREATE_BRANCH_OFFICE = gql`
    mutation DASHBOARD_crearSucursal(
        $code: String!
        $password: String!
        $description: String
        $lat: Float!
        $lng: Float!
        $currency: String!
    ) {
        DASHBOARD_crearSucursal(
            code: $code
            password: $password
            description: $description
            lat: $lat
            lng: $lng
            currency: $currency
        ) {
            id
            code
            password
            createdAt
            status
        }
    }
`;

export const CREATE_CATEGORY = gql`
    mutation CreateCategory(
        $name: String!
        $description: String
        $branchOffice: Int!
    ) {
        createCategory(
            name: $name
            description: $description
            branchOffice: $branchOffice
        ) {
            id
            name
            description
        }
    }
`;

export const CREATE_PRODUCT = gql`
    mutation CreateProduct(
        $name: String!
        $description: String
        $branchOffice: Int!
        $category: Int!
    ) {
        createProduct(
            name: $name
            description: $description
            branchOffice: $branchOffice
            category: $category
        ) {
            id
        }
    }
`;

export const CREATE_SIZE_PRODUCT = gql`
    mutation CreateSizeProduct($price: Float!, $sizeId: Int, $productId: Int!) {
        createSizeProduct(
            price: $price
            sizeId: $sizeId
            productId: $productId
        ) {
            id
            price
            size_id {
                id
                name
            }
        }
    }
`;

export const CREATE_FLAVOR = gql`
    mutation CreateFlavor(
        $name: String!
        $description: String
        $categoryId: Int!
    ) {
        createFlavor(
            name: $name
            description: $description
            categoryId: $categoryId
        ) {
            id
            name
            description
            status
        }
    }
`;

export const CREATE_TABLE = gql`
    mutation DASHBOARD_createTable(
        $name: String!
        $description: String
        $branchOffice: Int!
    ) {
        DASHBOARD_createTable(
            name: $name
            description: $description
            branchOffice: $branchOffice
        ) {
            id
        }
    }
`;

export const CREATE_KITCHEN_TABLE = gql`
    mutation CreateKitchenTable($name: String!, $branchOffice: Int!) {
        createKitchenTable(name: $name, branchOffice: $branchOffice) {
            id
        }
    }
`;

export const UPDATE_BRANCH_OFFICE = gql`
    mutation UpdateBranchOffice(
        $branchOfficeId: Int!
        $code: String
        $description: String
        $password: String
        $lat: Float
        $lng: Float
        $currency: String
    ) {
        updateBranchOffice(
            branchOfficeId: $branchOfficeId
            code: $code
            description: $description
            password: $password
            lat: $lat
            lng: $lng
            currency: $currency
        ) {
            id
            code
            latitude
            longitude
        }
    }
`;

export const DELETE_BRANCH_OFFICE = gql`
    mutation DASHBOARD_eliminarSucursal($branchOfficeId: Int!) {
        DASHBOARD_eliminarSucursal(branchOfficeId: $branchOfficeId) {
            id
        }
    }
`;

export const UPDATE_TABLE = gql`
    mutation DASHBOARD_updateTable($tableId: Int!, $status: Boolean, $name: String, $description: String) {
        DASHBOARD_updateTable(tableId: $tableId, status: $status, name: $name, description: $description) {
            id
        }
    }
`;

export const DELETE_TABLE = gql`
    mutation DASHBOARD_deleteTable($tableId: Int!) {
        DASHBOARD_deleteTable(tableId: $tableId) {
            id
        }
    }
`;

export const UPDATE_ORDER_TABLE = gql`
    mutation UpdateOrderTable($orderTableId: Int!, $name: String) {
        updateOrderTable(orderTableId: $orderTableId, name: $name) {
            id
        }
    }
`;

export const DELETE_ORDER_TABLE = gql`
    mutation DeleteOrderTable($orderTableId: Int!) {
        deleteOrderTable(orderTableId: $orderTableId) {
            id
        }
    }
`;

export const UPDATE_KITCHEN_TABLE = gql`
    mutation UpdateKitchenTable($kitchenTableId: Int!, $name: String) {
        updateKitchenTable(kitchenTableId: $kitchenTableId, name: $name) {
            id
        }
    }
`;

export const DELETE_KITCHEN_TABLE = gql`
    mutation DeleteKitchenTable($kitchenTableId: Int!) {
        deleteKitchenTable(kitchenTableId: $kitchenTableId) {
            id
        }
    }
`;

export const UPDATE_CATEGORY = gql`
    mutation UpdateCategory($category: CategoryInput!) {
        updateCategory(category: $category) {
            id
        }
    }
`;

export const DELETE_CATEGORY = gql`
    mutation DeleteCategory($categoryId: Int!) {
        deleteCategory(categoryId: $categoryId) {
            id
        }
    }
`;

export const UPDATE_FLAVOR = gql`
    mutation UpdateFlavor($flavor: FlavorInput!) {
        updateFlavor(flavor: $flavor) {
            id
            name
            description
        }
    }
`;

export const DELETE_FLAVOR = gql`
    mutation DeleteFlavor($flavorId: Int!) {
        deleteFlavor(flavorId: $flavorId) {
            id
        }
    }
`;

export const UPDATE_PRODUCT = gql`
    mutation UpdateProduct(
        $productId: Int!
        $name: String
        $description: String
    ) {
        updateProduct(
            productId: $productId
            name: $name
            description: $description
        ) {
            id
        }
    }
`;

export const DELETE_PRODUCT = gql`
    mutation DeleteProduct($productId: Int!) {
        deleteProduct(productId: $productId) {
            id
        }
    }
`;

export const UPDATE_SIZE_PRODUCT = gql`
    mutation UpdateSizeProduct($sizeProduct: SizeProductInput!) {
        updateSizeProduct(sizeProduct: $sizeProduct) {
            id
        }
    }
`;

export const DELETE_SIZE_PRODUCT = gql`
    mutation DeleteSizeProduct($sizeProductId: Int!) {
        deleteSizeProduct(sizeProductId: $sizeProductId) {
            id
        }
    }
`;

export const DELETE_ORDER = gql`
    mutation DASHBOARD_deleteOrder($orderId: Int!) {
        DASHBOARD_deleteOrder(orderId: $orderId) {
            id
        }
    }
`;

export const CREATE_ADMIN = gql`
    mutation CreateAdmin($email: String!, $password: String!) {
        createAdmin(email: $email, password: $password) {
            email
        }
    }
`;

export const DELETE_ADMIN = gql`
    mutation DeleteAdmin($adminId: Int!) {
        deleteAdmin(adminId: $adminId) {
            id
        }
    }
`;

export const DELETE_OPTION = gql`
    mutation DASHBOARD_deleteOption($optionId: Int!) {
        DASHBOARD_deleteOption(optionId: $optionId) {
            id
        }
    }
`;

export const DELETE_OPTION_MENU = gql`
    mutation DASHBOARD_deleteOptionMenu($id: Int!) {
        DASHBOARD_deleteOptionMenu(id: $id) {
            id
        }
    }
`;

export const UPDATE_PASSWORD = gql`
    mutation UpdatePassword($newPassword: String!) {
        updatePassword(newPassword: $newPassword) {
            id
        }
    }
`;

export const UPDATE_OPTION = gql`
    mutation DASHBOARD_updateOption(
        $optionId: Int!
        $required: Boolean
        $name: String
        $price: Float
    ) {
        DASHBOARD_updateOption(
            optionId: $optionId
            required: $required
            name: $name
            price: $price
        ) {
            id
        }
    }
`;

export const UPDATE_OPTION_MENU = gql`
    mutation DASHBOARD_updateOptionMenu(
        $id: Int!
        $name: String
        $required: Boolean
    ) {
        DASHBOARD_updateOptionMenu(
            id: $id
            name: $name
            required: $required
        ) {
            id
        }
    }
`;

export const CREATE_OPTION = gql`
    mutation DASHBOARD_createOption(
        $optionMenuId: Int!
        $name: String!
        $price: Float!
    ) {
        DASHBOARD_createOption(
            optionMenuId: $optionMenuId
            name: $name
            price: $price
        ) {
            id
        }
    }
`;

export const ACTUALIZAR_ROLES_DE_SUCURSAL = gql`
    mutation DASHBOARD_updateBranchOfficeRoles(
        $roles: [Actualizar_Roles_Input]!
    ) {
        DASHBOARD_updateBranchOfficeRoles(
            roles: $roles
        ) {
            id
        }
    }
`;

export const CREATE_OPTION_MENU = gql`
    mutation DASHBOARD_createOptionMenu(
        $categoryId: Int!
        $name: String!
        $required: Boolean
    ) {
        DASHBOARD_createOptionMenu(
            categoryId: $categoryId
            name: $name
            required: $required
        ) {
            id
        }
    }
`;