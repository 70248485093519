import React from 'react';

const almacenRoutes = [
    {
        path: '/almacen/crear',
        component: React.lazy(() => import('./almacenes/crearAlmacen')),
    },
    {
        path: '/almacen/inventario',
        component: React.lazy(() => import('./inventario/listarInventario')),
    },
    {
        path: '/almacen/crear-receta',
        component: React.lazy(() => import('./recetas/crearReceta')),
    },
    {
        path: '/almacen/recetas',
        component: React.lazy(() => import('./recetas/receta')),
    },
    {
        path: '/almacen/resumen-diario',
        component: React.lazy(() => import('./resumenDiario/resumenDiario')),
    },
];

export default almacenRoutes;
