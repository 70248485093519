import { combineReducers } from 'redux';
import LoginReducer from './LoginReducer';
import UserReducer from './UserReducer';
import LayoutReducer from './LayoutReducer';
import SucursalReducers from './bairu/SucursalReducers';
// import ScrumBoardReducer from "./ScrumBoardReducer";
// import NotificationReducer from "./NotificationReducer";
// import EcommerceReducer from "./EcommerceReducer";
import NavigationReducer from './NavigationReducer';

const RootReducer = combineReducers({
    login: LoginReducer,
    user: UserReducer,
    layout: LayoutReducer,
    // scrumboard: ScrumBoardReducer,
    // notification: NotificationReducer,
    // ecommerce: EcommerceReducer,
    navigations: NavigationReducer,
    // BAIRU
    sucursal: SucursalReducers,
});

export default RootReducer;
