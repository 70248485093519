import React from 'react';

const kitchenTablesRoutes = [
	{
		path: '/cocina/crear',
		component: React.lazy(() => import('./crearCocina')),
	},
	{
		path: '/cocina/listar',
		component: React.lazy(() => import('./Listar')),
	},
];

export default kitchenTablesRoutes;
