import React from 'react';

const administradoresRoutes = [
    {
        path: '/administradores',
        component: React.lazy(() => import('./admins')),
    },
];

export default administradoresRoutes;
