export const navigations = [
    {
        name: 'Dashboard',
        path: '/dashboard/analytics',
        icon: 'dashboard',
    },
    // BAIRU ROUTES
    {
        name: 'Administradores',
        path: '/administradores',
        icon: 'group',
    },
    {
        name: 'Sucursales',
        icon: 'domain',
        children: [
            {
                name: 'Crear sucursal',
                path: '/sucursales/crear',
                iconText: 'C',
            },
            {
                name: 'Mesas',
                path: '/sucursales/Mesas',
                iconText: 'M',
            },
            {
                name: 'Listar sucursales',
                path: '/sucursales/listar',
                iconText: 'C',
            },
        ],
    },
    {
        name: 'Mis productos',
        icon: 'local_dining',
        children: [
            {
                name: 'Crear categoria',
                path: '/categoria/crear',
                iconText: 'C',
            },
            {
                name: 'Crear producto',
                path: '/producto/crear',
                iconText: 'C',
            },
            {
                name: 'Listar categorias',
                path: '/categoria/listar',
                iconText: 'L',
            },
            {
                name: 'Listar productos',
                path: '/producto/listar',
                iconText: 'L',
            },
            {
                name: 'Opciones',
                path: '/opciones',
                iconText: 'O',
            },
        ],
    },
    {
        name: 'POS',
        icon: 'desktop_mac',
        children: [
            {
                name: 'POS',
                path: '/pos/pos-component',
                iconText: 'C',
            },
            // {
            //     name: 'Self Service',
            //     path: '/pos/selfservice-component',
            // },
        ],
    },
    {
        name: 'Cocina',
        icon: 'kitchen',
        children: [
            {
                name: 'Crear cocina',
                path: '/cocina/crear',
                iconText: 'C',
            },
            {
                name: 'Listar cocinas',
                path: '/cocina/listar',
                iconText: 'L',
            },
        ],
    },
    {
        name: 'Almacén',
        icon: 'store',
        children: [
            {
                name: 'Almacenes',
                path: '/almacen/crear',
                iconText: 'C',
            },
            {
                name: 'Inventario',
                path: '/almacen/inventario',
                iconText: 'L',
            },
            {
                name: 'Recetas',
                path: '/almacen/crear-receta',
                iconText: 'R',
            },
            {
                name: 'Resumen diario',
                path: '/almacen/resumen-diario',
                iconText: 'R',
            },
        ],
    },
    {
        name: 'Ventas',
        icon: 'local_grocery_store',
        children: [
            {
                name: 'Diarias',
                path: '/ventas/listar-fecha',
                iconText: 'D',
            },
            {
                name: 'Mensuales',
                path: '/ventas/listar-fecha-mes',
                iconText: 'M',
            },
            {
                name: 'Personalizado',
                path: '/ventas/personalizado',
                iconText: 'P',
                badge: { value: 'Nuevo', color: 'secondary' },
            },
        ],
    },
    // FIN BAIRU ROUTES
    // {
    // 	name: 'Drag and Drop',
    // 	icon: 'control_camera',
    // 	path: '/others/drag-and-drop',
    // },
    // {
    //     name: 'Multilevel',
    //     icon: 'trending_up',
    //     children: [
    //         {
    //             name: 'Level 1',
    //             icon: 'list',
    //             children: [
    //                 {
    //                     name: 'Item 1',
    //                     path: '/charts/victory-charts',
    //                     iconText: '1',
    //                 },
    //                 {
    //                     name: 'Item 2',
    //                     path: '/charts/react-vis',
    //                     iconText: '2',
    //                 },
    //                 {
    //                     name: 'Item 3',
    //                     path: '/charts/recharts',
    //                     iconText: '3',
    //                 },
    //                 {
    //                     name: 'Item 4',
    //                     path: '/charts/echarts',
    //                     iconText: '4',
    //                 },
    //             ],
    //         },
    //     ],
    // },
    // {
    // 	name: 'Utilities',
    // 	icon: 'format_list_bulleted',
    // 	children: [
    // 		{
    // 			name: 'Color',
    // 			path: '/utilities/color',
    // 			iconText: 'C',
    // 		},
    // 		{
    // 			name: 'Spacing',
    // 			path: '/utilities/spacing',
    // 			iconText: 'S',
    // 		},
    // 		{
    // 			name: 'Typography',
    // 			path: '/utilities/typography',
    // 			iconText: 'T',
    // 		},
    // 		{
    // 			name: 'Display',
    // 			path: '/utilities/display',
    // 			iconText: 'D',
    // 		},
    // 	],
    // },
    // {
    // 	name: 'Sessions',
    // 	icon: 'trending_up',
    // 	children: [
    // 		{
    // 			name: 'Sign in',
    // 			iconText: 'SI',
    // 			path: '/session/signin',
    // 		},
    // 		{
    // 			name: 'Sign up',
    // 			iconText: 'SU',
    // 			path: '/session/signup',
    // 		},
    // 		{
    // 			name: 'Forgot password',
    // 			iconText: 'FP',
    // 			path: '/session/forgot-password',
    // 		},
    // 		{
    // 			name: 'Error',
    // 			iconText: '404',
    // 			path: '/session/404',
    // 		},
    // 	],
    // },

    // {
    //     name: 'UI Kits',
    //     icon: 'favorite',
    //     badge: { value: '50+', color: 'secondary' },
    //     children: [
    //         {
    //             name: 'Auto Complete',
    //             path: '/material/autocomplete',
    //             iconText: 'A',
    //         },
    //         {
    //             name: 'Buttons',
    //             path: '/material/buttons',
    //             iconText: 'B',
    //         },
    //         {
    //             name: 'Checkbox',
    //             path: '/material/checkbox',
    //             iconText: 'C',
    //         },
    //         {
    //             name: 'Dialog',
    //             path: '/material/dialog',
    //             iconText: 'D',
    //         },
    //         {
    //             name: 'Expansion Panel',
    //             path: '/material/expansion-panel',
    //             iconText: 'E',
    //         },
    //         {
    //             name: 'Form',
    //             path: '/material/form',
    //             iconText: 'F',
    //         },
    //         {
    //             name: 'Icons',
    //             path: '/material/icons',
    //             iconText: 'I',
    //         },
    //         {
    //             name: 'Menu',
    //             path: '/material/menu',
    //             iconText: 'M',
    //         },
    //         {
    //             name: 'Progress',
    //             path: '/material/progress',
    //             iconText: 'P',
    //         },
    //         {
    //             name: 'Radio',
    //             path: '/material/radio',
    //             iconText: 'R',
    //         },
    //         {
    //             name: 'Switch',
    //             path: '/material/switch',
    //             iconText: 'S',
    //         },
    //         {
    //             name: 'Slider',
    //             path: '/material/slider',
    //             iconText: 'S',
    //         },
    //         {
    //             name: 'Snackbar',
    //             path: '/material/snackbar',
    //             iconText: 'S',
    //         },
    //         {
    //             name: 'Table',
    //             path: '/material/table',
    //             iconText: 'T',
    //         },
    //     ],
    // },

    // {
    // 	name: 'Map',
    // 	icon: 'add_location',
    // 	path: '/map',
    // },
];
