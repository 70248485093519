import React from 'react';
// import { alpha } from '@material-ui/core/styles'
// import { createTheme } from '@material-ui/core/styles'

const pedidosRoutes = [
    {
        path: '/ventas/listar-fecha',
        component: React.lazy(() => import('./diarias/listarVentas')),
    },
    {
        path: '/ventas/listar-fecha-mes',
        component: React.lazy(() => import('./mensuales/index')),
    },
    {
        path: '/ventas/personalizado',
        component: React.lazy(() => import('./personalizado/index')),
    }
];

export default pedidosRoutes;
