import React from 'react';

const orderTablesRoutes = [
    {
        path: '/pos/pos-component',
        component: React.lazy(() => import('./pos/pos-component.jsx')),
    },
    {
        path: '/pos/selfservice-component',
        component: React.lazy(() =>
            import('./self-service/self-service-component.jsx')
        ),
    },
];

export default orderTablesRoutes;
