import { InMemoryCache, ApolloClient, from, HttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import localStorageService from '../services/localStorageService';

const errorLink = onError(({ graphqlErrors, networkError }) => {
    if (graphqlErrors) {
        graphqlErrors.map(({ message, location, path }) => {
            console.log(`graphqlErrors: ${message}`);
        });
    }

    if (networkError) console.log(`[Network error]: ${networkError}`);
});

const link = from([
    errorLink,
    new HttpLink({ uri: `${process.env.REACT_APP_BAIRU_GRAPHQL}` }),
    // new HttpLink({ uri: 'http://localhost:4000/' }),
]);

const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = localStorageService.getItem('jwt_token');
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            Authorization: token ? `Bearer ${token}` : '',
        },
    };
});

export const Client = new ApolloClient({
    link: authLink.concat(link),
    cache: new InMemoryCache(),
});
