import React from 'react';

const productosRoutes = [
    {
        path: '/categoria/crear',
        component: React.lazy(() => import('./CrearCategoria')),
    },
    {
        path: '/producto/crear',
        component: React.lazy(() => import('./CrearProducto')),
    },
    {
        path: '/categoria/listar',
        component: React.lazy(() => import('./ListarCategorias')),
    },
    {
        path: '/producto/listar',
        component: React.lazy(() => import('./ListarProductos')),
    },
    {
        path: '/opciones',
        component: React.lazy(() => import('./opciones/index.jsx')),
    },
];

export default productosRoutes;
